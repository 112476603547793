#CookieOptOut {
	position: fixed;
	top: 50px;
	background: rgba(0, 0, 0, 0.64);
	width: 100%;
	max-width: 470px;
	box-sizing: border-box;
	z-index: 1000;
	left: 50%;
	transform: translateX(-50%);

	font-size: 12px;
	line-height: 15px;
	color: #ffffff;
	letter-spacing: 0;
	text-align: center;
	display: flex;
	flex-flow: column;
	align-items: center;
	padding: 26px;
	@media (min-width: 600px) {
		padding: 36px;
	}
	@media (min-width: 375px) {
		top: 60px;
	}
	@media (min-width: 768px) {
		top: 20px;
	}
	padding-bottom: 25px;

	.cookie-dialogue-actions {
		width: 100%;
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		align-items: center;
		margin-top: 25px;
		max-width: 392px;
	}

	p {
		width: 100%;
	}

	p a {
		color: inherit;
		display: inline-block;
		text-decoration: none;
		border-bottom: 1px solid #fff;
		padding-bottom: 5px;
	}

	a.cookie-dialogue-button {
		background: rgba(255, 255, 255, 0.8);
		color: #000000;
		font-size: 14px;
		line-height: 36px;
		height: 36px;
		width: calc(50% - 10px);
		max-width: 172px;
		display: block;
		text-decoration: none;
		transition: background 0.15s;
		cursor: pointer;

		&.cookie-dialogue-deny-button {
			background: rgba(255, 255, 255, 0.64);
		}

		&:hover {
			background: rgba(255, 255, 255, 1);
		}
	}
}
