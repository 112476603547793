@import '../../../../theme/variables';
@import '../../../../theme/poly_fluid_sizing';

@mixin setSituationLayerColors($colorController, $colorMobileToggle) {
	.player-timeline,
	.square-indicator,
	.module-text,
	.link-cta:hover {
		background-color: $colorController !important;

		span {
			color: #081338;
		}
	}

	.mobile-expand-layer {
		background: $colorMobileToggle;
	}

	.mobile-expand-toggle:before {
		background: linear-gradient(0deg, rgba($colorMobileToggle, 1) 20%, rgba($colorMobileToggle, 0) 100%);
	}
}

.situations-layer-container {
	position: relative;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	overflow: hidden;

	@include bp(m) {
		//left: 50%;
		//width: 50vw;
		display: flex;
		align-items: flex-end;
	}

	@media screen and (max-height: 760px) and (min-width: 1024px) {
		overflow: auto;
	}

	.oboen-layer {
		@include setSituationLayerColors(#f7e8c4, #f5de99);
	}

	.skrivebordet-layer {
		@include setSituationLayerColors(#f7e8c4, #e4d4b3);
	}

	.kontoret-layer {
		@include setSituationLayerColors(#ead8d5, #dec3bc);
	}

	.sofaen-layer {
		.situation-module__name {
			color: #706d7c;
		}

		.situation-layer__header,
		.module-text {
			color: $color-white;
		}
		@include setSituationLayerColors(#706d7c, #100c24);
	}

	.landevejen-layer {
		@include setSituationLayerColors(#e1e6dc, #cfdac9);
	}

	.opvasken-layer {
		@include setSituationLayerColors(#f9d0ae, #f6b178);
	}

	.pendlerne-layer {
		@include setSituationLayerColors(#f9d0ae, #f6b178);
	}

	.liggestolen-layer {
		@include setSituationLayerColors(#f9ebc2, #f5de99);
	}

	.situation-layer {
		//opacity: 0;
		visibility: hidden;
		pointer-events: none;
		position: absolute;
		right: 0;
		bottom: 0;
		width: 100%;
		z-index: 7;
		align-items: flex-end;
		height: 100%;
		user-select: none;

		@include bp(s) {
			display: flex;
		}

		@include bp(m) {
			height: 100%;
			width: 50%;
		}

		@include bp(l) {
			display: flex;
			align-items: center;
			justify-content: center;
			//height: 100%;
			//width: 50%;
		}

		@media screen and (max-height: 760px) and (min-width: 1024px) {
			//overflow-y: auto;
			//overflow-x: hidden;
			align-items: flex-start;
		}

		&__inner {
			height: 100%;
			display: flex;
			justify-content: flex-end;
			flex-direction: column;
			position: relative;

			@include bp(s) {
				height: auto;
				width: 100%;
				display: block;
			}

			@include bp(l) {
				width: auto;
			}

			@media screen and (max-height: 760px) and (min-width: 1024px) {
				padding-top: 50px;
				width: 100%;
			}

			.mobile-expand-layer {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				opacity: 0;
				visibility: hidden;

				@include bp(s) {
					display: none;
				}
			}

			.mobile-expand-toggle {
				width: 100%;
				position: absolute;
				bottom: 75px;
				left: 0;
				height: 100px;
				display: flex;
				align-items: flex-end;
				justify-content: center;
				padding-bottom: 12px;
				pointer-events: none;
				z-index: 2;

				@include bp(xs) {
					bottom: 90px;
				}

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					z-index: 4;
					transition: opacity 0.3s 0.1s;
				}

				.icon {
					position: relative;
					z-index: 6;
					transition: transform 0.3s 0s;

					svg {
						width: 12px;
					}
				}

				@include bp(s) {
					display: none;
				}
			}
		}

		&.is-mobile-toggled {
			.mobile-expand-toggle {
				&:before {
					opacity: 0;
				}

				.icon {
					transform: rotateZ(180deg);
				}
			}
		}

		&.video-is-fullscreen {
			z-index: 10;

			//display: none;

			//@include bp(s) {
			//	display: block;
			//}

			.video-expendable {
				position: fixed;
				width: 100% !important;
				height: 100% !important;
				padding: 0 100px;
				box-sizing: border-box;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				display: flex;
				align-items: flex-end;
				flex-direction: column;
				justify-content: center;
				max-width: 1440px;
				//opacity: 0;

				@media screen and (max-height: 760px) and (min-width: 1024px) {
					width: auto !important;
				}

				.video-wrapper {
					height: auto !important;

					@media screen and (max-height: 760px) and (min-width: 1024px) {
						height: 100% !important;
						max-height: calc(100% - 120px);
						width: auto !important;
					}
				}

				video {
					height: auto !important;
					width: 100%;

					@media screen and (max-height: 760px) and (min-width: 1024px) {
						height: 100% !important;
						width: auto;
					}
				}
			}

			.situation-layer__body {
				.situation-layer__fullscreen-cross {
					opacity: 1;
					visibility: visible;
				}
			}

			.main-bar-position {
				display: block !important;
			}
		}

		&.type-video {
			.situation-layer__body-wrapper {
				display: flex;
				flex-direction: row-reverse;

				@include bp(m) {
					flex-direction: column;
				}
			}

			.square-indicator {
				display: none;

				@include bp(m) {
					display: block;
				}
			}

			.player-duration {
				.separator,
				span:first-of-type {
					display: none;

					@include bp(m) {
						display: inline;
					}
				}
			}

			.player-timeline {
				display: none;

				@include bp(m) {
					display: block;
				}
			}

			.situation-main-bar {
				margin-left: 0;

				@include bp(m) {
					margin-left: auto;
				}
			}
		}

		&.type-podcast {
			.situation-layer__inner .situation-layer__body .situation-main-bar .situation-main-bar__content {
				padding-top: 18px;

				@include bp(xs) {
					padding-top: 22px;
				}

				@include bp(s) {
					padding-top: 24px;
				}
			}
		}

		&__header {
			width: 100%;
			max-width: 588px;
			box-sizing: border-box;
			padding: 0 16px 36px;
			margin-left: auto;

			@include bp(xs) {
				padding: 0 24px 36px;
			}

			@include bp(s) {
				margin-bottom: 45px;
				padding: 0;
			}

			.situation-uptitle {
				font-weight: 400;
				will-change: transform;
				$map: (
					320px: 16px,
					1024px: 24px
				);
				@include poly-fluid-sizing('font-size', $map);
				line-height: em(24, 16);

				@include bp(s) {
					font-size: 24px;
				}
			}

			.situation-name {
				font-weight: 700;
				will-change: transform;
				$map: (
					320px: 32px,
					1024px: 70px
				);
				@include poly-fluid-sizing('font-size', $map);
				margin-bottom: em(20, 32);
				line-height: 0.8em;
			}

			.situation-informations {
				display: flex;
				will-change: transform;
				$map: (
					320px: 15px,
					1024px: 30px
				);
				@include poly-fluid-sizing('margin-bottom', $map);

				&__item {
					//margin-right: 30px;
					width: 33.3%;
					will-change: transform;
					@include bp(xs) {
						min-width: 120px;
					}

					&:last-child {
						width: auto;
					}

					p:first-of-type {
						font-size: 12px;
						margin-bottom: 8px;
					}

					p:nth-of-type(2) {
						font-weight: 400;

						$map: (
							320px: 18px,
							1024px: 24px
						);
						@include poly-fluid-sizing('font-size', $map);
					}
				}
			}

			.situation-description {
				will-change: transform;

				@include bp(s) {
					padding-left: 120px;
				}

				p {
					font-size: 14px;
					max-width: 370px;
					line-height: 1.25em;
				}
			}
		}

		&__body {
			.situation-layer__fullscreen-cross {
				position: fixed;
				right: 0;
				top: 0;
				background: $color-black;
				height: 60px;
				width: 60px;
				opacity: 0;
				visibility: hidden;
				z-index: 5;

				.cross-bar {
					background: $color-white;
					left: calc(50% - 12px);

					&:before,
					&:after {
						background: $color-black;
					}
				}
			}

			.situation-layer__fullscreen-layer {
				position: fixed;
				left: 0;
				top: 0;
				z-index: 1;
				background-color: $color-black;
				opacity: 0;
				visibility: hidden;
				height: 100vh;
				width: 100vw;
			}

			.situation-main-bar {
				display: flex;
				width: 100%;
				margin-left: auto;
				position: relative;
				z-index: 4;

				@include bp(s) {
					max-width: 590px;
					width: 100%;
				}

				@include bp(m) {
					width: 590px;
				}

				@media screen and (max-height: 760px) and (min-width: 1024px) {
					width: 100%;
				}

				.square-indicator {
					height: 75px;
					width: 85px;
					flex-shrink: 0;
					position: relative;

					@include bp(xs) {
						height: 90px;
						width: 95px;
					}

					@include bp(s) {
						height: 120px;
						width: 120px;
					}

					img {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}

				&__content {
					width: 100%;
					box-sizing: border-box;
					display: flex;
					justify-content: space-between;
					padding: 12px 0 0;
					flex-direction: column;
					background-color: $color-white;
					position: relative;
					height: 75px;

					@include bp(xs) {
						height: 90px;
					}

					@include bp(s) {
						height: 120px;
						padding: 24px 0 0;
					}

					.player-timeline {
						width: 100%;
						position: absolute;
						overflow: hidden;
						top: 0;
						left: 0;
						height: 8px;
						flex-shrink: 0;
						cursor: pointer;

						@include bp(xs) {
							height: 12px;
						}

						@include bp(s) {
							height: 10px;
						}

						&__progress {
							background: $color-black;
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 8px;
							transform: translateX(-100%);

							@include bp(xs) {
								height: 12px;
							}

							@include bp(s) {
								height: 10px;
							}
						}
					}

					.top-bar {
						display: flex;
						align-items: center;
						justify-content: space-between;
						width: 100%;
						padding: 0px 12px;
						box-sizing: border-box;

						@include bp(xs) {
							padding: 0px 13px;
						}

						@include bp(s) {
							padding: 0px 25px;
						}

						p {
							color: #969696;
							font-size: 12px;
						}

						.player-duration {
							span:first-of-type {
								color: $color-black;
							}
						}

						.reading-duration {
							display: none;

							@include bp(s) {
								display: block;
							}

							span {
								color: $color-black;
							}
						}
					}

					.situation-module__main {
						padding: 8px 12px 12px;
						box-sizing: border-box;
						display: flex;
						align-items: flex-end;
						justify-content: space-between;

						@include bp(xs) {
							padding: 8px 13px 16px 13px;
						}

						@include bp(s) {
							padding: 8px 25px 16px 25px;
						}

						.situation-module__name {
							font-size: 12px;
							font-weight: 400;
							max-width: 200px;
							line-height: 1.125em;

							.english-version & {
								max-width: 235px;
							}

							@include bp(xs) {
								font-size: 14px;
							}

							a {
								text-decoration: none;
								color: inherit;
							}

							@include bp(s) {
								font-size: 20px;
							}

							span {
								display: block;
							}

							.language-warning {
								display: inline-block;
								font-size: 12px;
							}
						}

						.player-controls {
							display: flex;

							button {
								background: rgba(0, 0, 0, 0);
								border: 0;
								cursor: pointer;
								outline: none;
								transition: opacity 0.3s;
								position: relative;
								//padding: 0;
								margin-left: 14px;
								flex-shrink: 0;

								&.media-controller__button-fullscreen {
									display: none;

									@include bp(m) {
										display: inline-block;
									}
								}

								&.media-controller__button-mute {
									display: none;

									@include bp(s) {
										display: inline-block;
									}
								}

								img {
									height: 17px;
								}

								&.is-playing {
									.play-icon {
										opacity: 0;
									}

									.pause-icon {
										opacity: 1;
									}
								}

								&.is-muted {
									.sound-on {
										opacity: 0;
									}

									.sound-off {
										opacity: 1;
									}
								}

								&.is-fullscreen {
									.full-in {
										opacity: 0;
									}

									.full-out {
										opacity: 1;
									}
								}

								.pause-icon,
								.sound-off,
								.full-out {
									opacity: 0;
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
								}

								.sound-off,
								.sound-on {
									width: 22px;
								}
								.sound-off {
									top: 0;
									left: 0;
									transform: none;
								}
							}
						}
					}

					.link-cta {
						position: absolute;
						right: 0;
						bottom: 0;
						//padding: 10px 13px;
						line-height: 0;
						cursor: pointer;
						height: 34px;
						width: 34px;
						box-sizing: border-box;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: $color-black;
						transition: background-color 0.3s;

						svg {
							width: 8px;
						}

						a {
							font-size: 12px;
							text-decoration: none;
							color: $color-white;
							transition: color 0.3s;
						}
					}
				}
			}

			.situation-main-content {
				display: flex;
				justify-content: flex-end;

				@include bp(m) {
					height: 240px;
				}

				.video-container {
					flex-shrink: 0;
					line-height: 0;
					height: 75px;
					width: calc((75 * 1920 / 1080) * 1px);

					@include bp(xs) {
						height: 90px;
						width: calc((90 * 1920 / 1080) * 1px);
					}

					@include bp(s) {
						height: 120px;
						width: calc((120 * 1920 / 1080) * 1px);
					}

					@include bp(m) {
						height: 100%;
						width: calc((240 * 1920 / 1080) * 1px);
					}

					.video-wrapper,
					.video-expendable {
						height: 100%;
						width: 100%;
						z-index: 4;
					}

					.main-bar-position {
						display: none;
						height: 120px;
						width: 590px;
						flex-shrink: 0;
					}

					video {
						height: 100%;
						cursor: pointer;
						will-change: transform;
					}
				}

				.module-text {
					width: 360px;
					height: 240px;
					padding: 24px 24px 0px 24px;
					box-sizing: border-box;
					display: none;
					flex-shrink: 0;

					@include bp(m) {
						display: block;
					}

					p {
						font-size: 14px;
						line-height: 1.25em;
					}
				}
			}
		}
	}
}
