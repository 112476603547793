@import '../../../../theme/variables.scss';
@import '../../../../theme/poly_fluid_sizing';

.logo {
	width: 100px;

	svg {
		width: 100%;

		path {
			transition: 0.6s fill;

			.dark-theme & {
				fill: #fff;
			}
		}
	}
}

#LoadingIndicator {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	font-size: 16px;
	color: #000000;
	letter-spacing: 2.08px;
	text-align: center;
	z-index: 3;
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.2s;
	display: flex;
	flex-flow: column;

	.percentage {
		margin-top: 10px;
	}
}

#ControlsIntroduction {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;
	padding: 20px;
	padding-bottom: 40px;
	text-align: center;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	display: flex;
	flex-flow: column;
	align-items: center;
	will-change: transform;

	p {
		max-width: 500px;
		margin-top: 20px;
	}

	&:not(.touch) {
		p {
			margin-top: 64px;
		}
	}

	.KeyboardGFX {
		width: 61px;
		height: 40px;
		display: block;
	}

	.ControlsWrapper {
		position: relative;

		span {
			position: absolute;
			display: inline-block;
		}

		.ControlsTextForward {
			text-align: center;
			top: -16px;
			left: 0;
			width: 100%;
			transform: translate3d(0, -100%, 0);
		}

		.ControlsTextBackward {
			bottom: -16px;
			left: 0;
			width: 100%;
			transform: translate3d(0, 100%, 0);
		}

		.ControlsTextLeft {
			left: -16px;
			bottom: 0;
			transform: translate3d(-100%, 0, 0);
		}

		.ControlsTextRight {
			right: -16px;
			bottom: 0;
			transform: translate3d(100%, 0, 0);
		}
	}

	.TouchGFX {
		width: 38px;
		height: 76px;
		display: none;
	}

	.TouchDescription {
		display: none;
	}

	&.touch {
		.TouchDescription {
			display: block;
		}

		.KeyboardDescription {
			display: none;
		}

		.ControlsWrapper {
			display: none;
		}

		.TouchGFX {
			display: block;
		}
	}
}

.VideoSubtitles {
	& > div {
		position: absolute;
		color: #000000;
		margin-left: 5px;
		margin-right: 5px;
		transition: opacity 0.1s;
		p {
			background: rgba(255, 255, 255, 0.54);
			padding-bottom: 4px;
			padding-top: 4px;
			display: inline;
			box-shadow: 10px 0 0 rgba(255, 255, 255, 0.54), -10px 0 0 rgba(255, 255, 255, 0.54);
		}
	}
	width: 100%;
	color: #000000;
	letter-spacing: 0;
	text-align: center;
	line-height: 26px;
	pointer-events: none;
	transition: opacity 0.2s;
	position: absolute;
	bottom: 170px;
	max-width: 300px;
	left: 50% !important;
	margin-left: -150px;
	font-size: 15px;

	@media (min-width: 600px) {
		max-width: 500px;
		left: 50% !important;
		margin-left: -250px;
		font-size: 20px;
		line-height: 32px;
	}
}

.skip-intro {
	background: transparent;
	color: $color-black;
	cursor: pointer;
	outline: none;
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: 1px;
	text-align: center;
	border: 2px solid #000000;
	width: 120px;
	height: 28px;
	opacity: 0;
	transition: opacity 0.5s, background 0.15s, color 0.15s;
	visibility: hidden;
	padding: 0;
	margin: 0;
	margin-top: 21px;
	position: absolute;
	bottom: 32px;
	left: calc(50% - 60px);
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	@media (min-width: 600px) {
		left: calc(50% - 70px);

		letter-spacing: 2.08px;
		font-size: 16px;
		width: 140px;
		height: 34px;
	}
	&:hover {
		background: $color-black;
		color: $color-white;
	}

	span {
		@include BrownPro(400);
	}
}

.intro {
	position: absolute;
	background: $color-pink;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	overflow: hidden;
	z-index: 10;

	video {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		opacity: 0;
		pointer-events: none;
	}

	.intro__cta {
		background: transparent;
		color: $color-black;
		cursor: pointer;
		outline: none;
		text-transform: uppercase;
		font-size: 16px;
		letter-spacing: 2.08px;
		text-align: center;
		border: 2px solid #000000;
		width: 80px;
		height: 34px;
		opacity: 0;
		transition: opacity 0.5s, background 0.15s, color 0.15s;
		padding: 0;
		margin: 0;
		margin-top: 21px;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

		&:hover {
			background: $color-black;
			color: $color-white;
		}

		span {
			@include BrownPro(400);
		}
	}

	&__inner {
		position: relative;
		display: flex;
		flex-flow: column;
		align-items: center;
	}

	&-title {
		text-align: center;
		text-transform: uppercase;
		@include BrownPro(700);
		will-change: transform;

		.title {
			line-height: 1em;

			$map: (
				320px: 42px,
				1024px: 93px
			);
			@include poly-fluid-sizing('font-size', $map);
		}

		.subtitle {
			$map: (
				320px: 16px,
				1024px: 38px
			);
			@include poly-fluid-sizing('font-size', $map);
			line-height: 1em;
			margin-top: -0.1em;
			letter-spacing: 0.23em;
		}
	}

	&__about-headphones {
		display: flex;
		align-items: center;
		flex-direction: column;

		.headset-icon {
			width: 42px;
			margin-bottom: 18px;

			img {
				max-width: 100%;
			}
		}

		p {
			text-align: center;
			max-width: 300px;
			$map: (
				320px: 13px,
				400px: 16px
			);
			@include poly-fluid-sizing('font-size', $map);
			@include bp(m) {
				max-width: 100%;
			}
			text-transform: uppercase;
			letter-spacing: 2.08px;
			line-height: 1.2em;
		}
	}

	&__bottom-part {
		position: absolute;
		bottom: 0;
		left: 0;
		padding-bottom: 32px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
