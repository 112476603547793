@import '../../../../theme/variables.scss';
@import '../../../../theme/poly_fluid_sizing';

.experience-container {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;

	.webgl-container {
		position: relative;
		z-index: 1;

		canvas {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			//cursor: grab;

			&:nth-child(1) {
				left: 0;
			}

			&:nth-child(2) {
				right: 0;
			}
		}
	}

	.overall-mute {
		position: fixed;
		bottom: -2px;
		left: 0;
		padding-left: 23px;
		z-index: 5;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		width: 40px;
		height: 40px;
		display: none;

		@include bp(m) {
			display: flex;
		}

		&__bar {
			height: 4px;
			width: 3px;
			margin-right: 3px;
			will-change: transform;
			background-color: $color-black;
			transform-origin: bottom center;

			.dark-theme & {
				background-color: $color-white;
			}
		}
	}
}
