.video-layer {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 9;
	width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;

	.cross {
		z-index: 8;
	}

	.layer-opaque {
		position: absolute;
		background-color: $color-pink;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 1;
		opacity: 0.8;
	}

	.layer-container {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 8;
		width: 100%;
		display: flex;
		align-items: flex-end;
		flex-direction: column;

		.layer-main-content {
			position: relative;
			width: auto;
			right: 0;
			top: 0;
			background: $color-yellow-light;
			padding: 0 155px;
			box-sizing: border-box;
			z-index: 6;
		}

		.layer-module-content {
			max-width: 560px;
			padding: 0 30px;
			background: $color-yellow-light;
			height: 22vh;
			display: flex;
			align-items: center;
			box-sizing: border-box;

			p {
				font-size: 12px;
				line-height: 15px;

				&:first-child {
					font-weight: 700;
				}
			}
		}
	}

	.cross {
		position: absolute;
		top: 40px;
		right: 36px;
	}

	.video-player {
		max-width: 812px;

		.top-bar {
			padding: 36px 0;
		}

		.title {
			font-size: 12px;
			font-weight: 700;
			position: relative;
			border-bottom: 2px solid $color-black;
			box-sizing: border-box;
			display: inline-block;
			padding: 0 2px 4px 2px;
		}

		.video-container {
			position: relative;
		}

		.video {
			width: 100%;
			max-height: 450px;
			height: 54vh;

			video {
				max-width: 100%;
				height: 100%;
			}
		}

		.video-controller {
			display: flex;
			width: 100%;
			align-items: center;
			padding: 36px 0;
			position: relative;

			&__actions {
				display: flex;
				align-items: center;
				justify-content: space-between;

				button {
					background: rgba(0, 0, 0, 0);
					border: none;
					padding: 0;
					margin-right: 25px;
					cursor: pointer;
					outline: none;

					&:last-child {
						margin: 0;
					}
				}
			}

			&__timeline {
				height: 6px;
				background: #e6e6e6;
				flex-grow: 1;
				overflow: hidden;
				margin: 0 25px;
				cursor: pointer;

				.progress-bar {
					width: 100%;
					height: 100%;
					background: $color-white;
					transform: translate3d(-40%, 0, 0);
				}
			}

			&__duration {
				p,
				span {
					display: inline-block;
				}
			}
		}
	}

	.socials-module {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		height: 155px;
		align-items: center;
		transform: translate3d(0%, 0, 0) rotateZ(90deg);
		transform-origin: top left;

		p {
			margin-right: 30px;
			font-size: 12px;
			font-weight: 700;
		}

		.socials-list {
			&__item {
				display: inline-block;
				transform: rotateZ(-90deg);
				margin-right: 21px;
			}
		}
	}
}
