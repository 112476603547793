$font-main: 'Brown Pro';
$color-yellow: #fac644;
$color-yellow-light: #f2c85d;
$color-white: #fff;
$color-black: #000;
$color-pink: #dcbeb7;
$color-grey-light: #878787;

@import 'theme/variables.scss';
@import 'theme/reset.scss';
//@import 'theme/fonts.scss';
@import 'theme/partials.scss';

@import 'theme/layers/loader.scss';
@import 'theme/layers/video-layer.scss';

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	user-select: none;
}

html,
body {
	width: 100%;
	height: 100%;
	min-height: 100vh;
	min-height: -moz-available;
	min-height: -webkit-fill-available;
	min-height: fill-available;
	overflow: hidden;
	background: $color-pink;
	font-family: $font-main;
	font-weight: 400;
}

#webglmessage {
	position: absolute;
	top: -12px;
	left: 0;
	right: 0;
	box-sizing: border-box;
	width: 100% !important;
	max-width: 400px;

	&:after {
		display: block;
		margin-top: 10px;
		content: 'Most of this website will not work!';
	}
}

.test {
	position: relative;
	z-index: 0;
}

#app {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 0;
	width: 100%;
	height: 100%;

	.preloading {
		position: fixed;
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;

		.ufo-icon {
			height: 56px;
			opacity: 0;

			img {
				height: 100%;
			}
		}
	}
}

.mobile-left-navigation,
.mobile-right-navigation {
	position: absolute;
	top: 40%;
	z-index: 8;
	width: 12px;
	height: 30vh;
	display: flex;
	align-items: center;
	transform: translateY(-50%);

	@include bp(m) {
		display: none;
	}

	svg {
		height: 14px;
		opacity: 0;
		visibility: hidden;

		path {
			transition: stroke 0.3s;

			.dark-theme & {
				stroke: #fff;
			}
		}
	}
}

.mobile-left-navigation {
	left: 0;
	padding: 0 12px 0 4px;

	@include bp(xs) {
		padding: 0 12px 0 6px;
	}

	svg {
		transform: translateX(20px);
	}
}

.mobile-right-navigation {
	right: 0;
	padding: 0 4px 0 12px;

	@include bp(xs) {
		padding: 0 6px 0 12px;
	}

	svg {
		transform: translateX(-20px);
	}
}
