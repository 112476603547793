@import '../../../../theme/variables.scss';
@import '../../../../theme/poly_fluid_sizing';

.main-navigation {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;

	.header {
		width: 100%;
		padding: 10px 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-sizing: border-box;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;

		@include bp(xs) {
			padding: 19px 24px;
		}

		@include bp(s) {
			padding: 32px 35px;
		}

		.logo {
			width: 80px;
			//opacity: 0;

			@include bp(xs) {
				width: 100px;
			}

			svg {
				width: 100%;

				path {
					transition: 0.6s fill;
				}
			}
		}

		.dark-theme & {
			.logo svg path {
				fill: #fff;
			}
		}

		&.menu-is-open {
			.logo svg path {
				fill: #000;
			}
		}

		.burger-icon {
			width: 30px;
			cursor: pointer;
			padding: 8px 0;
			box-sizing: border-box;
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.2s;
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

			&__bar {
				height: 2px;
				width: 100%;
				background: #000;
				transition: 0.6s background ease-in-out;
				position: relative;
				z-index: 8;
				will-change: transform;

				span {
					display: inline-block;
					background: #ffffff;
					height: 4px;
					position: absolute;
					width: 100%;
					left: 0;
					opacity: 0;

					&:nth-child(1) {
						top: 2px;
					}

					&:nth-child(2) {
						top: -4px;
					}
				}

				&:nth-child(2) {
					margin: 3px 0px;
					z-index: 10;
				}

				&:nth-child(4) {
					margin: 3px 0 0;
					z-index: 10;
					opacity: 0;
				}
			}

			.dark-theme &:not(.menu-is-open) {
				.burger-icon__bar {
					background: #fff;
				}
			}
		}
	}

	.navigation {
		position: fixed;
		z-index: 8;
		height: 100%;
		width: 100%;
		opacity: 0;
		visibility: hidden;
		overflow: hidden;
		-webkit-overflow-scrolling: touch;

		@include bp(m) {
			overflow: initial;
		}

		&__inner {
			width: 100%;
			height: 100%;
			overflow-y: scroll;
			overflow-x: hidden;
			//background: $color-white;

			@include bp(m) {
				width: auto;
				height: auto;
				overflow: initial;
			}
		}

		.opaque-layer {
			position: absolute;
			background-color: $color-black;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			z-index: 1;
			opacity: 0.8;
			display: none;

			@include bp(s) {
				display: block;
			}
		}

		&-main {
			z-index: 6;
			width: 100%;
			min-height: 100%;
			background-color: $color-white;
			align-items: center;
			transform: translate3d(100%, 0, 0);
			position: relative;
			display: block;
			padding-top: 100px;
			padding-bottom: 17px;
			box-sizing: border-box;

			@include bp(s) {
				height: 100%;
				display: flex;
			}

			@include bp(m) {
				width: 50vw;
				height: calc(100% - 90px);
				position: absolute;
				right: 0;
				top: 0;
				padding-top: 0;
				padding-bottom: 0;
				min-height: auto;
			}

			@media screen and (min-width: 1024px) and (max-height: 750px) {
				overflow-y: scroll;
				overflow-x: hidden;
				-webkit-overflow-scrolling: touch;
				align-items: flex-start;
				padding-top: 100px;
			}
		}

		&-top-bar {
			width: 100%;
			display: flex;
			position: absolute;
			top: 0;
			left: 0;
			align-items: center;
			justify-content: space-between;
			padding: 20px 36px 0 25px;
			box-sizing: border-box;

			@include bp(s) {
				padding: 36px 36px 0 77px;
			}

			.title {
				font-size: 12px;
				font-weight: 700;
				position: relative;
				border-bottom: 2px solid $color-black;
				padding: 0 2px 4px 2px;
				top: 4px;
				box-sizing: border-box;
				opacity: 0;
				display: none;

				@include bp(m) {
					display: block;
				}
			}
		}

		&-side-bar {
			position: relative;
			transform: none;
			top: 0;
			padding: 0 24px;
			display: flex;
			justify-content: flex-end;

			@include bp(xs) {
				padding: 0 32px;
			}

			@include bp(s) {
				position: absolute;
				right: 0;
				top: 145px;
				transform: translate3d(100%, 0, 0) rotateZ(90deg);
				transform-origin: top left;
				padding-top: 41px;
			}

			a {
				text-decoration: none;
				font-size: 12px;
				font-weight: 700;
				display: inline-block;
				opacity: 0;
				transform: translate3d(0, -32px, 0);

				&,
				&:hover,
				&:visited {
					color: $color-black;
				}

				&:nth-child(1) {
					margin-right: 30px;
				}
			}
		}

		&-menu {
			counter-reset: item;
			padding-left: calc(25px + 24px);
			max-width: 100%;
			box-sizing: border-box;
			padding-top: 40px;
			padding-right: 24px;

			@include bp(xs) {
				padding-left: calc(25px + 32px);
				padding-right: 32px;
			}

			@include bp(s) {
				padding-left: calc(77px + 40px);
				max-width: 100%;
			}

			&__item {
				margin-bottom: 40px;
				opacity: 0;
				transform: translate3d(30px, 0, 0);

				.menu-link {
					text-decoration: none;
					position: relative;

					&:hover {
						&:before {
							transform: scale(1.2);
						}
					}

					&:before {
						counter-increment: item; /* Increment the value of section counter by 1 */
						content: '0' counter(item);
						position: absolute;
						left: -30px;
						top: 0;
						font-size: 12px;
						line-height: 20px;
						transition: transform 0.6s;

						@include bp(s) {
							left: -40px;
						}
					}

					&,
					&:hover,
					&:visited {
						color: $color-black;
					}

					&__title {
						font-size: 20px;
						line-height: 1em;
						font-weight: 700;
						margin-bottom: 8px;
						max-width: 500px;
						padding-right: 50px;
						box-sizing: border-box;

						@include bp(s) {
							font-size: 36px;
						}
					}

					&__text {
						font-size: 13px;
						line-height: 1.25em;
						max-width: 320px;
					}
				}
			}
		}

		&-module {
			width: 50vw;
			max-width: 400px;
			height: 90px;
			background-color: $color-white;
			position: absolute;
			right: 50vw;
			bottom: 0;
			z-index: 6;
			padding: 0 30px;
			transform: translate3d(calc(100% + 50vw), 0, 0);
			display: none;

			a {
				text-decoration: none;
				color: $color-black;
			}

			@include bp(m) {
				display: block;
			}

			&__wrapper {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				opacity: 0;
			}

			&__content {
				p {
					font-size: 12px;

					&:nth-child(1) {
						font-weight: 700;
						margin-bottom: 2px;
					}
				}
			}

			&__icon {
				width: 20px;

				svg {
					max-width: 100%;
				}
			}
		}
	}
}
