@import '../../../../theme/variables.scss';

.fast-travel {
	position: fixed;
	left: 50%;
	bottom: 16px;
	transform: translateX(-50%);
	z-index: 6;
	//display: none;
	opacity: 0;
	visibility: hidden;
	width: 100%;
	text-align: center;
	user-select: none;

	@include bp(m) {
		//display: block;
		width: auto;
		text-align: left;
		bottom: auto;
		left: 25px;
		top: 50%;
		transform: translate3d(0, -50%, 0);
	}

	&-introduction {
		position: absolute;
		left: 50%;
		top: -52px;
		transform: translateX(-50%);
		display: flex;
		align-items: center;
		flex-direction: column-reverse;

		@include bp(m) {
			top: 50%;
			left: 32px;
			transform: translate(0, -50%);
			flex-direction: row;
		}

		.icon {
			//opacity: 0;
			transform: rotateZ(-90deg);
			margin-top: 12px;
			line-height: 0;

			@include bp(m) {
				margin-right: 12px;
				margin-top: 0;
				transform: rotateZ(0);
			}
		}

		svg {
			width: 21px;
			flex-shrink: 0;
		}

		p {
			white-space: nowrap;
			font-size: 12px;
			font-weight: 700;
			//opacity: 0;
		}
	}

	.text-separator {
		height: 1px;
		width: 15px;
		background: $color-black;
		margin: 0 6px;
		transition: 0.3s background;
		display: inline-block;
	}

	.fast-travel-label-list {
		position: absolute;
		top: 0;
		left: 50%;
		text-align: center;

		.fast-travel-label {
			position: absolute;
			left: 0;
			top: -50px;
			transform: translateX(-50%);
			opacity: 0;
			white-space: nowrap;
			user-select: none;

			span {
				display: inline-block;
				vertical-align: middle;
			}
		}

		@include bp(m) {
			display: none;
		}
	}

	&-list {
		display: inline-block;
		//padding: 0 8px;

		@include bp(m) {
			padding: 0;
		}

		&__item {
			position: relative;
			cursor: pointer;
			line-height: 0;
			padding: 16px 8px;
			box-sizing: border-box;
			display: inline-block;

			@include bp(m) {
				display: block;
				padding: 8px;
			}

			&.home-icon {
				position: relative;
				left: -3px;
				display: none;

				@include bp(m) {
					display: inline-block;
				}

				svg {
					width: 10px;

					path {
						transition: 0.6s stroke;

						.dark-theme & {
							stroke: #fff;
						}
					}
				}
			}

			.dot {
				display: inline-block;
				height: 5px;
				width: 5px;
				background: $color-black;
				border-radius: 50%;
				position: relative;
				z-index: 4;
				line-height: 0;
				transition: 0.6s background;

				.dark-theme & {
					background: $color-white;
				}
			}

			.text {
				font-size: 12px;
				font-weight: 700;
				opacity: 0;
				position: absolute;
				white-space: nowrap;
				transform: translateX(-200%);
				user-select: none;
				display: none;

				@include bp(m) {
					display: block;
				}

				.text-inner {
					display: flex;
					opacity: 0;
					transform: translateX(-20px);
					transition: 0.6s transform, 0.3s opacity;
					align-items: center;
					user-select: none;
				}

				.dark-theme & {
					color: #fff;

					.text-separator {
						background: $color-white;
					}
				}
			}

			&.is-hovering,
			&.is-active {
				@include bp(m) {
					.dot {
						opacity: 0;
					}
				}

				.text {
					opacity: 1;
					transform: translateX(0%);
				}

				.text-inner {
					opacity: 1;
					transform: translateX(0px);
				}
			}
		}
	}
}
