.loader {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: $color-pink;
	z-index: 8;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;

	&-waiting-text {
		max-width: 666px;
		text-align: center;
		font-size: 26px;
		line-height: 32px;
	}

	&-bar {
		max-width: 332px;
		width: 100%;
		overflow: hidden;
		height: 18px;
		background: #e6e6e6;
		position: absolute;
		left: 50%;
		bottom: 26px;
		transform: translate3d(-50%, 0, 0);
		border-radius: 9px;
		box-sizing: border-box;
		border: 3px solid $color-white;

		&__progress {
			height: 100%;
			width: 100%;
			background: $color-yellow;
			transform: translate3d(-100%, 0, 0);
			border-radius: 9px;
		}
	}
}
