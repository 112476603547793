.cross {
	position: relative;
	width: 24px;
	height: 24px;
	cursor: pointer;

	&-bar {
		height: 2px;
		width: 24px;
		background: $color-black;
		position: absolute;
		left: 0;
		top: calc(50% - 1px);

		&:nth-child(1) {
			transform: rotateZ(45deg);
		}

		&:nth-child(2) {
			transform: rotateZ(-45deg);

			&:before,
			&:after {
				width: 100%;
				height: 4px;
				background: $color-white;
				content: '';
				position: absolute;
			}

			&:before {
				top: -4px;
			}
			&:after {
				top: 2px;
			}
		}
	}
}
